import React from "react"
import Layout from "../components/Layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Card, Col, Row } from "antd"
import SEO from "../components/Seo"


const colSettings = {
  small: {
    xs: 24, sm: 12, md: 6, lg: 4, xl: 3
  },
  medium: {
    xs: 24, sm: 12, md: 6, lg: 4, xl: 3
  },
  large: {
    xs: 24, sm: 24, md: 12, lg: 8, xl: 6
  }
}

const rowSettings = {
  gutter: 16
}

const StandCard = ({ title, slug, image, cardClass }) => (
  <Link to={`/${slug}`}>
    <Card
      hoverable
      style={{ width: "100%" }}
      cover={<img alt={title} src={image} />}
      className={`round ${cardClass}`}
    >
      <Card.Meta title={title} />
    </Card>
  </Link>
)

const SponsorStand = () => {
  const { allWpSponsorStand, wp } = useStaticQuery(graphql`
      {
          allWpSponsorStand {
              nodes {
                  title
                  slug
                  elenco_moderatori {
                      immagineDiAnteprima {
                          localFile {
                              childImageSharp {
                                  fixed(width: 300) {
                                      src
                                  }
                              }
                          }
                      }
                  }
                  single_stand {
                      grandezzaStand
                  }
              }
          }
          wp {
              opzioniDelTema {
                  opzioni_archivio_stands {
                      backgroundArchivioStand {
                          localFile {
                              childImageSharp {
                                  original {
                                      src
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

  const standRows = {}

  allWpSponsorStand.nodes.map((node) => {
    const { single_stand } = node
    standRows[single_stand?.grandezzaStand] = standRows[single_stand?.grandezzaStand] || []
    standRows[single_stand?.grandezzaStand].push(node)
  })

  return (
    <Layout>
      <SEO title={'Sponsor'}/>
      <div className="sponsor-stand-wrapper"
           style={{ backgroundImage: `url(${wp.opzioniDelTema.opzioni_archivio_stands.backgroundArchivioStand.localFile.childImageSharp.original.src})` }}>
        <Row {...rowSettings}>
          {standRows["grande"]?.map(({ title, slug, single_stand, elenco_moderatori }) => (
            <Col key={slug} {...colSettings.large}>
              <StandCard title={title} slug={slug} cardClass={"large"}
                         image={elenco_moderatori.immagineDiAnteprima?.localFile.childImageSharp.fixed.src} />
            </Col>
          ))}
        </Row>
        <Row {...rowSettings}>
          {standRows["medio"]?.map(({ title, slug, single_stand, elenco_moderatori }) => (
            <Col key={slug} {...colSettings.mediu}>
              <StandCard title={title} slug={slug} cardClass={"medium"}
                         image={elenco_moderatori.immagineDiAnteprima?.localFile.childImageSharp.fixed.src} />
            </Col>
          ))}
        </Row>
        <Row {...rowSettings}>
          {standRows["piccolo"]?.map(({ title, slug, single_stand, elenco_moderatori }) => (
            <Col key={slug} {...colSettings.small}>
              <StandCard title={title} slug={slug} cardClass={"small"}
                         image={elenco_moderatori.immagineDiAnteprima?.localFile.childImageSharp.fixed.src} />
            </Col>
          ))}
        </Row>
      </div>
    </Layout>
  )
}

export default SponsorStand
